import {
  Dropdown,
  DropDownOptionProps,
  Overlay,
  ProgressIndicatorLoader,
} from "@rpe-js/marcom-web-components";
import { isEmpty } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useSearchParams } from "react-router";
import { BaseRefData } from "../../../shared/types/refData";
import {
  DataUsageConsent,
  PrivacyPolicy as PrivacyPolicyType,
} from "../../../shared/types/talent/talent";
import { TemplateFormData } from "../../../shared/types/talent/template";
import {
  getCountryRefData,
  getPrivacyPolicyTemplateForm,
  updatePrivacyPolicy,
} from "../../api/fetchClient";
import {
  COUNTRY_CHINA,
  DATA_USAGE_CONSENT_POLICY,
  PRIVACY_POLICY_ELEMENT_SELECTORS,
} from "../../app.constants";
import AppContext from "../../AppContext";
import { useFetchData } from "../../hooks/useFetchData";
import useFocusFirstErrorField from "../../hooks/useFocusFirstErrorField";
import useIntlMessage from "../../hooks/useIntlMessage";
import useIsMobile from "../../hooks/useIsMobile";
import { idGenerator } from "../../utils/idGenerator";
import { AlertWrapper } from "../base/AlertWrapper";
import HtmlRenderer from "../base/HtmlRenderer";
import { SaveCancelAction } from "./saveAndCancel";

function getElementById(id: string): HTMLInputElement {
  return document.getElementById(id) as HTMLInputElement;
}

function isDOMCheckBoxChecked(id: string): boolean {
  return getElementById(id)?.checked;
}

interface PrivacyPolicyProps {
  editMode?: boolean;
  disableCountrySelector?: boolean;
  showModal?: boolean;
  cancelButtonText?: string;
  onCloseModal?: () => void;
  // This callback is used from profile contact information section.
  onAcceptPrivacyPolicy?: (data: PrivacyPolicyType) => void;
  defaultCountryId?: string;
  dataUsageConsentObj?: DataUsageConsent | null;
  //data usage consent checkbox will be enabled in view mode with save and cancel button
  editDataUsageConsent?: boolean;
  triggerElementId: string; // Dom Element Id which needs to be focused on close of the modal
  disableFocusOut: boolean; //don't restore focus on CLOSE
  saveButtonText?: string;
}
const PrivacyPolicy = ({
  editMode = true,
  showModal = true,
  onCloseModal,
  cancelButtonText,
  onAcceptPrivacyPolicy,
  defaultCountryId,
  disableCountrySelector,
  dataUsageConsentObj,
  editDataUsageConsent = false,
  disableFocusOut,
  triggerElementId,
  saveButtonText,
}: PrivacyPolicyProps) => {
  const [searchParams] = useSearchParams();
  const returnUrl = searchParams.get("returnUrl");
  const { appUIState } = useContext(AppContext);
  const { locale } = appUIState.appData;
  const { t } = useIntlMessage();
  const isMobile = useIsMobile();
  const [visible, setVisible] = useState<boolean>(editMode || showModal);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [countryRefData, setCountryRefData] = useState<BaseRefData[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [displayAcceptError, setDisplayAcceptError] = useState(false);
  const [displayCountryError, setDisplayCountryError] = useState(false);
  const [globalContent, setGlobalContent] = useState("");
  const [globalAcceptContent, setGlobalAcceptContent] = useState("");
  const [regionalContent, setRegionalContent] = useState("");
  const [acceptInProgress, setAcceptInProgress] = useState(false);
  const {
    GLOBAL_CHECKBOX,
    REGIONAL_CHECKBOX,
    REGIONAL_ACCEPT_ERROR,
    PAL_PRIVACY_POLICY_CHECKBOX,
    CONTENT_PRIVACY,
  } = PRIVACY_POLICY_ELEMENT_SELECTORS;
  const [privacyTemplateForm, setPrivacyTemplateForm] =
    useState<TemplateFormData>();
  const countryDropdownParentId = idGenerator(
    "privacypolicy",
    "countruyDropdownParent",
  ).generateId();
  const { setFocusFirstErrorField } = useFocusFirstErrorField(
    countryDropdownParentId,
  );
  const [dataConsentOptIn, setDataConsentOptIn] = useState<boolean>(
    dataUsageConsentObj?.optIn || false,
  );
  const [dataPrivacyConsentEvent, setDataPrivacyConsentEvent] =
    useState<boolean>(false);
  //Until the user scrolls to the end of the container the save will be disabled.
  const [isScrollEnd, setIsScrollEnd] = useState(false);
  const [dataUsagePolicyConsentTxt, setDataUsagePolicyConsentTxt] =
    useState("");
  const location = useLocation();
  const removeRegionPrivacyDefaultCheck = useCallback(() => {
    setTimeout(() => {
      const globalCBElement = getElementById(GLOBAL_CHECKBOX);
      const regionalCBElement = getElementById(REGIONAL_CHECKBOX);
      if (globalCBElement && editMode) {
        globalCBElement.removeAttribute("checked");
        globalCBElement.removeAttribute("disabled");
      }
      if (regionalCBElement && editMode) {
        regionalCBElement.removeAttribute("checked");
        regionalCBElement.removeAttribute("disabled");
      }
    }, 100);
  }, [GLOBAL_CHECKBOX, REGIONAL_CHECKBOX, editMode]);

  const {
    data: privacyTemplateFormData,
    isLoading: isPrivacyTemplateDataLoading,
    isSuccess: isPrivacyTemplateDataSuccess,
    fetchData: fetchPrivacyFormData,
    isError: isPrivacyTemplateDataError,
  } = useFetchData<TemplateFormData>(getPrivacyPolicyTemplateForm);

  const {
    data: countryRefDta,
    isLoading: isCountryRefDtaLoading,
    isSuccess: isCountryRefDtaSuccess,
    fetchData: fetchCountryRefData,
    isError: isCountryRefDtaError,
  } = useFetchData<BaseRefData[]>(getCountryRefData);

  useEffect(() => {
    if (
      !countryRefDta &&
      !isCountryRefDtaLoading &&
      editMode &&
      !isCountryRefDtaError
    ) {
      fetchCountryRefData();
    }
    if (editMode && countryRefDta && isCountryRefDtaSuccess) {
      setCountryRefData(countryRefDta);
    }
  }, [
    fetchCountryRefData,
    isCountryRefDtaLoading,
    countryRefDta,
    isCountryRefDtaSuccess,
    editMode,
    isCountryRefDtaError,
  ]);

  const options: DropDownOptionProps[] = useMemo(() => {
    if (countryRefData) {
      const opts: DropDownOptionProps[] = [
        {
          label: t("jobsite.common.selectCountry") as string,
          value: "",
          disabled: true,
        },
      ];
      countryRefData.forEach((crd) => {
        opts.push({
          label: crd.name,
          value: crd.id,
        });
      });
      return opts;
    } else {
      return [];
    }
  }, [countryRefData, t]);

  const onCountryChange = useCallback(
    (evt: React.ChangeEvent, option: DropDownOptionProps) => {
      setSelectedCountry(option.value as string);
      setDisplayAcceptError(false);
      setDisplayCountryError(false);
    },
    [],
  );

  const onDropDownBlur = useCallback(
    (evt: React.FocusEvent<Element, Element>) => {
      evt.stopPropagation();
      if (!isEmpty(selectedCountry)) setDisplayCountryError(false);
    },
    [selectedCountry],
  );

  useEffect(() => {
    if (
      !isPrivacyTemplateDataLoading &&
      !privacyTemplateFormData &&
      !isPrivacyTemplateDataError
    ) {
      fetchPrivacyFormData();
    }

    if (
      isPrivacyTemplateDataSuccess &&
      privacyTemplateFormData &&
      !privacyTemplateForm
    ) {
      setPrivacyTemplateForm(privacyTemplateFormData);
      if (privacyTemplateFormData.countryID) {
        if (!editMode) {
          // in view mode, we show accepted privacypolicy country
          setCountryRefData([
            {
              id:
                privacyTemplateFormData.acceptedCountryID ||
                privacyTemplateFormData.countryID,
              name: privacyTemplateFormData.acceptedCountryName || "",
            },
          ]);
          setSelectedCountry(
            privacyTemplateFormData.acceptedCountryID ||
              privacyTemplateFormData.countryID,
          );
        } else {
          setSelectedCountry(privacyTemplateFormData.countryID);
        }
      }
      if (defaultCountryId) {
        setSelectedCountry(defaultCountryId);
      }
      setGlobalContent(privacyTemplateFormData?.name || "");
      setGlobalAcceptContent(privacyTemplateFormData?.acceptText || "");

      if (
        privacyTemplateFormData &&
        privacyTemplateFormData.additionalPrivacyPolicy?.length
      ) {
        const dataUsagePolicyObj =
          privacyTemplateFormData.additionalPrivacyPolicy.find(
            (dataUsagePolicy) =>
              dataUsagePolicy.name === DATA_USAGE_CONSENT_POLICY,
          );
        setDataUsagePolicyConsentTxt(dataUsagePolicyObj?.acceptText || "");
      }
    }
  }, [
    fetchPrivacyFormData,
    isPrivacyTemplateDataLoading,
    setGlobalContent,
    setGlobalAcceptContent,
    isPrivacyTemplateDataSuccess,
    privacyTemplateFormData,
    setCountryRefData,
    editMode,
    privacyTemplateForm,
    defaultCountryId,
    setDataUsagePolicyConsentTxt,
    isPrivacyTemplateDataError,
  ]);

  useEffect(() => {
    if (selectedCountry === COUNTRY_CHINA && privacyTemplateForm) {
      const apc = (privacyTemplateForm.additionalPrivacyPolicy || []).find(
        (apc) => apc.countryID === COUNTRY_CHINA,
      );
      setRegionalContent(apc?.acceptText as string);
      if (editMode) {
        removeRegionPrivacyDefaultCheck();
      }
    }
  }, [
    privacyTemplateForm,
    selectedCountry,
    removeRegionPrivacyDefaultCheck,
    editMode,
  ]);

  const resetPolicyCheckboxes = useCallback(
    (globalPolicyChecked: boolean, regionalPolicyChecked: boolean) => {
      setTimeout(() => {
        const globalCBElement = getElementById(GLOBAL_CHECKBOX);
        const regionalCBElement = getElementById(REGIONAL_CHECKBOX);
        if (globalCBElement && editMode) {
          globalCBElement.removeAttribute("checked");
          globalCBElement?.removeAttribute("disabled");
          if (globalPolicyChecked) {
            globalCBElement.checked = true;
          }
        }
        if (regionalCBElement && editMode) {
          regionalCBElement.removeAttribute("checked");
          regionalCBElement.removeAttribute("disabled");
          if (regionalPolicyChecked) {
            regionalCBElement.checked = true;
          }
        }
      }, 10);
    },
    [GLOBAL_CHECKBOX, REGIONAL_CHECKBOX, editMode],
  );

  useEffect(() => {
    if (editMode && isPrivacyTemplateDataSuccess && isCountryRefDtaSuccess)
      removeRegionPrivacyDefaultCheck();
  }, [
    editMode,
    removeRegionPrivacyDefaultCheck,
    isPrivacyTemplateDataSuccess,
    isCountryRefDtaSuccess,
  ]);

  useEffect(() => {
    if (!editMode && privacyTemplateForm) {
      setDataLoading(false);
    }
    if (editMode && countryRefData && privacyTemplateForm) {
      setDataLoading(false);
    }
    if (isPrivacyTemplateDataError) {
      setDataLoading(false);
    }
  }, [
    editMode,
    countryRefData,
    privacyTemplateForm,
    isPrivacyTemplateDataError,
    t,
  ]);

  // TODO: change the return type while adding API calls
  const validate = useCallback((): any => {
    let hasError = false;
    const globalPolicyChecked = isDOMCheckBoxChecked(GLOBAL_CHECKBOX);
    const regionalPolicyChecked = isDOMCheckBoxChecked(REGIONAL_CHECKBOX);

    if (!selectedCountry) {
      setDisplayCountryError(true);
      hasError = true;
    }

    if (selectedCountry === COUNTRY_CHINA) {
      if (!globalPolicyChecked || !regionalPolicyChecked) {
        hasError = true;
        setDisplayAcceptError(true);
        setTimeout(() => {
          getElementById(REGIONAL_ACCEPT_ERROR)?.scrollIntoView();
        }, 100);
        resetPolicyCheckboxes(globalPolicyChecked, regionalPolicyChecked);
      } else {
        setDisplayCountryError(false);
        setDisplayAcceptError(false);
      }
    }
    resetPolicyCheckboxes(globalPolicyChecked, regionalPolicyChecked);
    return hasError;
  }, [
    GLOBAL_CHECKBOX,
    REGIONAL_CHECKBOX,
    REGIONAL_ACCEPT_ERROR,
    resetPolicyCheckboxes,
    selectedCountry,
  ]);

  const getDataConsentObject = useCallback(
    (dataConsentOptInValue: boolean): DataUsageConsent => {
      const dataConsentObject = {
        optIn: dataConsentOptInValue || false,
        acknowledgedDate: Date.now(),
        version: privacyTemplateFormData?.version,
      };
      if (dataPrivacyConsentEvent) {
        return dataConsentObject;
      } else {
        return dataUsageConsentObj ? dataUsageConsentObj : dataConsentObject;
      }
    },
    [
      dataPrivacyConsentEvent,
      dataUsageConsentObj,
      privacyTemplateFormData?.version,
    ],
  );

  const savePrivacyPolicy = useCallback(
    async (selectedCountry: string, dataConsentOptIn: boolean) => {
      const hasError = validate();
      if (!hasError) {
        setAcceptInProgress(true);
        const dataConsentObject = dataUsagePolicyConsentTxt
          ? getDataConsentObject(dataConsentOptIn)
          : null;
        try {
          if (returnUrl) {
            await updatePrivacyPolicy(
              {
                countryID: selectedCountry,
                acknowledgedDate: Date.now(),
                version: privacyTemplateFormData?.version,
                dataConsent: dataConsentObject,
              },
              returnUrl as string,
            );
          } else {
            const returnUrl = location.pathname;
            await updatePrivacyPolicy(
              {
                dataConsent: dataConsentObject,
              },
              returnUrl,
            );
          }
        } catch (error: any) {}
      }
    },
    [
      dataUsagePolicyConsentTxt,
      getDataConsentObject,
      location.pathname,
      privacyTemplateFormData?.version,
      returnUrl,
      validate,
    ],
  );

  const onAccept = useCallback(
    (selectedCountry: string, dataConsentOptIn: boolean) => {
      if (!selectedCountry) {
        setFocusFirstErrorField(true);
      }

      if (onAcceptPrivacyPolicy) {
        if (validate()) return;
        onAcceptPrivacyPolicy({
          countryID: selectedCountry,
          acknowledgedDate: Date.now(),
          version: privacyTemplateFormData?.version,
          dataConsent: dataUsagePolicyConsentTxt
            ? getDataConsentObject(dataConsentOptIn)
            : null,
        });
        return;
      } else savePrivacyPolicy(selectedCountry, dataConsentOptIn);
    },
    [
      dataUsagePolicyConsentTxt,
      onAcceptPrivacyPolicy,
      savePrivacyPolicy,
      setFocusFirstErrorField,
      validate,
      privacyTemplateFormData?.version,
      getDataConsentObject,
    ],
  );

  const onReject = useCallback(() => {
    if (onCloseModal) {
      onCloseModal();
      return;
    }
    window.location.href = `/${locale}/privacy/reject`;
  }, [locale, onCloseModal]);

  const onClose = useCallback(() => {
    if (!editMode) {
      setVisible(false);
      if (onCloseModal) {
        onCloseModal();
      }
    }
  }, [editMode, onCloseModal]);

  const handleCheckboxChange = (e: Event) => {
    const { checked } = e.target as HTMLInputElement;
    setDataPrivacyConsentEvent(true);
    setDataConsentOptIn(checked);
  };

  const handleScroll = useCallback(() => {
    const modalContent = getElementById(CONTENT_PRIVACY);
    if (modalContent && !isScrollEnd) {
      const scrollTop = modalContent.scrollTop;
      const scrollHeight = modalContent.scrollHeight;
      const clientHeight = modalContent.clientHeight;

      const threshold = 10;
      if (scrollHeight - scrollTop - clientHeight <= threshold) {
        setIsScrollEnd(true);
      }
    }
  }, [CONTENT_PRIVACY, isScrollEnd]);

  useEffect(() => {
    setTimeout(() => {
      const palCBElement = getElementById(PAL_PRIVACY_POLICY_CHECKBOX);
      if (palCBElement) {
        if (dataUsageConsentObj?.optIn) {
          palCBElement.checked = dataUsageConsentObj.optIn;
        }
        if (editDataUsageConsent || editMode) {
          palCBElement.removeAttribute("disabled");
          palCBElement.addEventListener("change", handleCheckboxChange);
        }
      }

      const modalContent = getElementById(CONTENT_PRIVACY);
      if (modalContent && (editDataUsageConsent || editMode)) {
        modalContent.addEventListener("scroll", handleScroll);
      }
    }, 20);
    // Cleanup the event listener when the component unmounts
    return () => {
      const palCBElement = getElementById(PAL_PRIVACY_POLICY_CHECKBOX);
      const modalContent = getElementById(CONTENT_PRIVACY);
      if (modalContent) {
        modalContent.removeEventListener("scroll", handleScroll);
      }
      if (palCBElement) {
        palCBElement.removeEventListener("change", handleCheckboxChange);
      }
    };
  }, [
    dataUsageConsentObj,
    globalContent,
    PAL_PRIVACY_POLICY_CHECKBOX,
    CONTENT_PRIVACY,
    handleScroll,
    editDataUsageConsent,
    editMode,
  ]);

  //not elegant, but all globalContent comes in as one big HTML string with non-uniform fonts
  const globalContent17px = globalContent.replace(
    "font-size: 14px",
    "font-size: 17px",
  );

  return (
    <>
      {dataLoading && <ProgressIndicatorLoader showLoading={true} />}
      {!dataLoading && (
        <Overlay
          id={idGenerator("privacypolicy", "overlay").generateId()}
          wide={true}
          noCloseButton={editMode}
          disableFocusOut={disableFocusOut}
          elementIdToFocus={triggerElementId}
          visible={visible}
          onClose={onClose}
          disableEsc={false}
          isFullscreen={isMobile}
          closeButtonAttrs={{
            ariaLabel: t("jobsite.common.close") as string,
          }}
          contentAttrs={{
            "aria-label": t("jobsite.common.candidatePrivacyPolicy") as string,
          }}
          footerContent={
            editMode || editDataUsageConsent ? (
              <div className="d-flex justify-center mt-20 mb-40 ">
                <SaveCancelAction
                  classes={"justify-center"}
                  onCancel={editDataUsageConsent ? onClose : onReject}
                  onSave={() =>
                    onAccept(
                      selectedCountry as string,
                      dataConsentOptIn as boolean,
                    )
                  }
                  cancelLabelName={
                    cancelButtonText
                      ? cancelButtonText
                      : (t("jobsite.common.signOut") as string)
                  }
                  saveLabelName={
                    saveButtonText
                      ? saveButtonText
                      : (t("jobsite.common.acceptContinue") as string)
                  }
                  cancelButtonId={idGenerator(
                    "privacypolicy",
                    "cancel",
                  ).generateId("button")}
                  saveButtonId={idGenerator(
                    "privacypolicy",
                    "accept",
                  ).generateId("button")}
                  saveDisabled={
                    acceptInProgress ||
                    (editDataUsageConsent
                      ? !dataPrivacyConsentEvent || !isScrollEnd
                      : !isScrollEnd)
                  }
                />
              </div>
            ) : (
              <></>
            )
          }
        >
          <div
            className="d-flex flex-column pos-rel"
            id={countryDropdownParentId}
          >
            {(isCountryRefDtaLoading || isPrivacyTemplateDataLoading) && (
              <ProgressIndicatorLoader showLoading={true} />
            )}
            {isPrivacyTemplateDataError && (
              <AlertWrapper
                id={idGenerator("page", "alert").generateId()}
                error={true}
                className="red text-center"
                alignCenter={false}
              >
                {t("jobsite.common.serviceError") as string}
              </AlertWrapper>
            )}
            {privacyTemplateForm && (
              <>
                <Dropdown
                  required={true}
                  name={t("jobsite.common.selectCountry") as string}
                  label={t("jobsite.common.selectCountry") as string}
                  options={options}
                  allowNone={false}
                  id={idGenerator("privacypolicy", "country").generateId()}
                  value={selectedCountry}
                  error={
                    displayCountryError
                      ? (t("jobsite.common.errorCountry") as string)
                      : ""
                  }
                  errorA11y={t("jobsite.common.errorIconLabel") as string}
                  handleValueSelect={onCountryChange}
                  onBlur={onDropDownBlur}
                  disabled={!editMode || disableCountrySelector}
                  aria-required="true"
                />
                <div
                  id="content-privacy"
                  className="privacy-policy-content text-left mb-20 hyperlink-underline"
                >
                  <HtmlRenderer
                    initialContent={globalContent17px}
                  ></HtmlRenderer>
                  {/* Add globalAcceptContent here */}
                  {selectedCountry !== COUNTRY_CHINA && (
                    <HtmlRenderer
                      initialContent={globalAcceptContent}
                      classes="text-left hyperlink-underline"
                    />
                  )}

                  {/* Add regionalAcceptContent here */}
                  {(selectedCountry === COUNTRY_CHINA ||
                    dataUsagePolicyConsentTxt) && (
                    <div
                      role="group"
                      aria-label={
                        t("jobsite.common.candidatePrivacyPolicy") as string
                      }
                      className="text-left ml-5"
                    >
                      {selectedCountry === COUNTRY_CHINA && (
                        <HtmlRenderer
                          initialContent={regionalContent}
                        ></HtmlRenderer>
                      )}
                      {dataUsagePolicyConsentTxt && (
                        <div>
                          <HtmlRenderer
                            initialContent={dataUsagePolicyConsentTxt}
                            classes="text-left"
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {displayAcceptError && (
                    <div
                      id={REGIONAL_ACCEPT_ERROR}
                      className="error-message"
                      role="alert"
                      aria-live="polite"
                    >
                      {t("jobsite.common.privacyAcceptError")}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </Overlay>
      )}
    </>
  );
};

export default PrivacyPolicy;
